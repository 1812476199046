import { AboutTitel, AboutText, AboutContainer } from './About.styled';

export default function About() {
  return (
    <AboutContainer>
      <AboutTitel>
        ТДВ "Моторика-Юг" виробництво вібропресового обладнання
      </AboutTitel>
      <AboutText>
        ТДВ «Моторика–Юг» з'явилося у Миколаєві 1993 року. Основу трудового
        колективу становили колишні працівники НВО «Тор», які перейшли в галузь
        високоточного машинобудування з військової галузі. Основним напрямом діяльності нової фірми
        стало виробництво вібропресового обладнання. Можливості наявної
        виробничої бази дозволяли виконувати будь-які роботи у сфері
        машинобудування.
      </AboutText>
      <AboutText>
        Одночасно великим попитом стало користуватися і допоміжне обладнання для
        виробництва тротуарної плитки, будівельних блоків та інших бетонних
        елементів. І зараз, через роки, більшість техніки, випущеної ТДВ
        «Моторика-Юг», продовжує працювати як в Україні, так і в інших країнах.
        Накопичено величезний досвід роботи у цій галузі.
      </AboutText>
      <AboutText>
        За минулі роки вібропресове обладнання, вироблене фірмою, неодноразово
        модернізувалося з урахуванням останніх розробок у галузі
        вібропресування. В даний час ми випускаємо напівавтоматичне та
        автоматичне обладнання з зоною формування 0,36м2, та 0,7м2, який
        повністю розроблений та втілений у металі співробітниками нашого
        підприємства. Підприємство виготовляє різноманітні
        конструкційно-технологічні варіанти прес-форм як для вібропресів
        власного виробництва, так і для вібропресів вітчизняних ("Строймаш",
        "Нартекс", "ТМ БУМ", "УМБР", "ВАСТ СЕРВІС+" та ін.) та зарубіжних
        виробників ("Zenith", "HESS", "OMAG", "Schlosser", "Knauer", "Masa",
        "Frima", "ZZBO", "ГЕВІТ" тощо).
      </AboutText>
      <AboutText>
        Пропонується до реалізації багато нових позицій допоміжного обладнання
        для виробництва тротуарної плитки, будівельних блоків та інших бетонних
        елементів. Це комплекси для виготовлення товарного та напівсухого бетону
        продуктивністю 20…60м3/год, спліттери для бетонних блоків, комплекси для
        перекладки та упаковки готової продукції, системи з
        транспортно-складальною групою, системи з транспортними стелажами,
        системи фарбування виробів ColorMix, системи для відмивання тротуарної
        плитки та очищення технологічних піддонів тощо.
      </AboutText>
      <AboutText>
        На підприємстві сконструйовано, зібрано і вже успішно пройшло
        випробування новий привід віброблоку, що дозволив значно підвищити
        ущільнення суміші та скоротити час вібрації. Ми надаємо послуги з
        обслуговування та капітального ремонту вібропрессового обладнання
        будь-якого типу.
      </AboutText>
      <AboutText>
        На сьогоднішній день в електрощитах, збірка яких також проводиться
        нашими фахівцями, в основному застосовуються комплектуючі фірми
        Schneider Electric, що дозволяє підвищити надійність роботи обладнання і
        звести до мінімуму кількість відмов його електричної частини.
      </AboutText>
      <AboutText>
        Якщо ви вже ведете, або ж тільки вирішили розпочати бізнес, пов'язаний
        із виробництвом тротуарної плитки, будівельних блоків та інших бетонних
        елементів та хочете купити вібропрес в Україні, запрошуємо звернутися до
        нас - ТДВ «Моторика-Юг».
      </AboutText>
      <AboutText>
        Наші фахівці, які мають багаторічний досвід у виробництві вібропресового
        обладнання та завжди нададуть посильну допомогу своїм клієнтам як
        словом, так і ділом!
      </AboutText>
    </AboutContainer>
  );
}
