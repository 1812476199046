import { BsTools } from 'react-icons/bs';
import {
  FeaturesContainer,
  FeaturesItem,
  FeaturesText,
} from './Features.styled';

export default function Features() {
  return (
    <FeaturesContainer>
      <FeaturesItem>
        <svg
          fill="#000000"
          version="1.1"
          id="Capa_1"
          width="80"
          height="80"
          viewBox="0 0 418.879 418.879"
        >
          <g>
            <g>
              <path
                fill="#02759e"
                d="M188.634,234.066c8.462-5.287,16.126-11.735,22.767-19.127l23.942,13.826l21.8-37.76l-23.894-13.796
			c3.038-9.275,4.825-19.113,5.16-29.311l27.183-4.793l-7.574-42.938l-27.178,4.793c-3.756-9.309-8.795-17.965-14.906-25.744
			l17.766-21.17L200.3,30.022l-17.751,21.152c-8.67-4.646-18.059-8.119-27.954-10.203V13.385h-43.6v27.586
			c-9.896,2.084-19.285,5.557-27.956,10.203l-17.75-21.152l-33.4,28.025l17.764,21.17c-6.11,7.779-11.149,16.436-14.905,25.744
			L7.57,100.168L0,143.106l27.179,4.793c0.335,10.199,2.123,20.035,5.161,29.313L8.444,191.007l21.801,37.759l23.943-13.822
			c6.639,7.389,14.303,13.838,22.766,19.125l-9.451,25.963l40.972,14.91l9.438-25.928c4.864,0.688,9.831,1.053,14.882,1.053
			c5.051,0,10.019-0.363,14.883-1.053l9.437,25.93l40.97-14.914L188.634,234.066z M132.793,200.065
			c-30.702,0-55.68-24.977-55.68-55.68c0-30.701,24.978-55.68,55.68-55.68s55.68,24.979,55.68,55.68
			C188.473,175.088,163.496,200.065,132.793,200.065z"
              />
              <path
                fill="#02759e"
                d="M376.041,266.807l-18.795,6.08c-3.584-6.229-8.014-11.869-13.115-16.779l10.504-16.764l-26.447-16.57l-10.498,16.75
			c-6.604-2.438-13.602-3.973-20.826-4.471l-2.725-19.559l-30.912,4.309l2.725,19.559c-6.809,2.453-13.125,5.847-18.812,9.996
			l-14.672-13.244l-20.912,23.168l14.684,13.259c-3.562,6.118-6.277,12.752-8.02,19.726l-19.744-0.714l-1.129,31.188l19.743,0.716
			c1.246,7.198,3.486,13.991,6.558,20.271l-15.578,12.143l19.185,24.615l15.609-12.164c5.438,4.582,11.511,8.396,18.031,11.311
			l-4.138,19.344l30.522,6.52l4.133-19.314c3.516,0.01,7.072-0.229,10.652-0.727c3.582-0.498,7.07-1.25,10.447-2.215l9.256,17.451
			l27.574-14.623l-9.266-17.471c5.48-4.586,10.271-9.918,14.252-15.812l18.338,7.436l11.727-28.924l-18.303-7.422
			c1.234-6.875,1.529-14.027,0.764-21.293l18.799-6.084L376.041,266.807z M297.129,350.006
			c-21.771,3.031-41.949-12.209-44.98-33.977c-3.037-21.769,12.207-41.949,33.977-44.979c21.768-3.036,41.941,12.207,44.98,33.978
			C334.135,326.795,318.896,346.969,297.129,350.006z"
              />
              <path
                fill="#02759e"
                d="M418.146,158.647l0.732-24.629l-15.586-0.463c-0.977-5.428-2.723-10.803-5.285-15.971l12.24-9.67l-15.271-19.33
			l-12.238,9.666c-4.365-3.627-9.193-6.584-14.318-8.816l3.164-15.291l-24.123-4.996l-3.17,15.281
			c-5.559,0.008-11.156,0.797-16.641,2.412l-7.391-13.727l-21.695,11.684l7.391,13.729c-4.363,3.686-8.107,7.934-11.176,12.566
			l-14.496-5.77l-9.111,22.893l14.508,5.779c-0.955,5.508-1.141,11.158-0.514,16.799l-14.809,4.898l7.732,23.395l14.809-4.896
			c2.9,4.986,6.426,9.396,10.426,13.201l-8.191,13.268l20.963,12.946l8.209-13.292c5.285,1.896,10.828,3.051,16.453,3.414
			l2.252,15.453l24.383-3.561l-2.246-15.434c2.602-0.957,5.17-2.109,7.684-3.463c2.516-1.352,4.891-2.867,7.123-4.51l11.648,10.371
			l16.387-18.398l-11.656-10.383c2.795-4.9,4.875-10.164,6.203-15.619L418.146,158.647z M359.436,171.844
			c-15.281,8.227-34.404,2.492-42.627-12.783c-8.23-15.277-2.494-34.404,12.787-42.627c15.273-8.229,34.395-2.49,42.625,12.787
			C380.443,144.499,374.711,163.616,359.436,171.844z"
              />
            </g>
          </g>
        </svg>
        <FeaturesText>
          Технологічні лінії, які проектуються з врахуванням вимог Замовника та
          території проекту.
        </FeaturesText>
      </FeaturesItem>
      <FeaturesItem>
        <BsTools size={70} color={'#02759e'} />
        <FeaturesText>
          Склад запасних частин в Україні. В наявності широкий асортимент
          якісних комплектуючих та запасних частин.
        </FeaturesText>
      </FeaturesItem>
      <FeaturesItem>
        <svg
          fill="#000000"
          height="70"
          width="70"
          version="1.1"
          id="Icons"
          viewBox="0 0 32 32"
        >
          <path
            fill="#02759e"
            d="M31.7,20.9c-0.1-0.5-0.7-0.8-1.2-0.7c-0.7,0.2-1.2,0-1.3-0.2c-0.1-0.2,0-0.7,0.5-1.3c0.4-0.4,0.4-1,0-1.4
	c-1-1-2.2-1.7-3.6-2.1c-0.5-0.1-1.1,0.2-1.2,0.7c-0.2,0.7-0.6,1-0.9,1s-0.6-0.4-0.9-1c-0.2-0.5-0.7-0.8-1.2-0.7
	c-1.4,0.4-2.6,1.1-3.6,2.1c-0.4,0.4-0.4,1,0,1.4c0.5,0.5,0.6,1,0.5,1.3c-0.1,0.2-0.6,0.4-1.3,0.2c-0.5-0.1-1.1,0.2-1.2,0.7
	C16.1,21.6,16,22.3,16,23s0.1,1.4,0.3,2.1c0.1,0.5,0.7,0.8,1.2,0.7c0.7-0.2,1.2,0,1.3,0.2c0.1,0.2,0,0.7-0.5,1.3
	c-0.4,0.4-0.4,1,0,1.4c1,1,2.2,1.7,3.6,2.1c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.3,1-0.7c0.2-0.7,0.6-1,0.9-1s0.6,0.4,0.9,1
	c0.2,0.5,0.7,0.8,1.2,0.7c1.4-0.4,2.6-1.1,3.6-2.1c0.4-0.4,0.4-1,0-1.4c-0.5-0.5-0.6-1-0.5-1.3c0.1-0.2,0.6-0.4,1.3-0.2
	c0.5,0.1,1.1-0.2,1.2-0.7c0.2-0.7,0.3-1.4,0.3-2.1S31.9,21.6,31.7,20.9z M24,27c-2.2,0-4-1.8-4-4s1.8-4,4-4c2.2,0,4,1.8,4,4
	S26.2,27,24,27z"
          />
          <path
            fill="#02759e"
            d="M14,23c0-1.2,0.2-2.3,0.6-3.3c0-0.1,0-0.1,0-0.2c0,0,0.1-0.1,0.1-0.1c0.5-1.2,1.1-2.2,1.9-3.1c0.1-0.4,0.2-0.7,0.3-1.1
	c0-0.3,0-0.6-0.2-0.8S16.2,14,15.9,14c-1.1,0-2.1-0.4-2.9-1h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-0.2v-0.2c0-1.8-0.6-3.4-1.6-4.8l-0.7,2.5
	c-0.1,0.4-0.5,0.7-1,0.7c-0.1,0-0.2,0-0.3,0c-0.5-0.2-0.8-0.7-0.7-1.2l1.2-4.1c0-0.3-0.2-0.6-0.5-0.8c-2.6-1.3-5.7-1.3-8.3,0
	C6.5,3.1,6.4,3.5,6.3,3.8l1.2,4.1c0.2,0.5-0.2,1.1-0.7,1.2c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8-0.3-1-0.7L4.9,6c-1,1.4-1.6,3-1.6,4.8V11
	H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h2v1c0,2.2,0.9,4.2,2.4,5.5c-0.2,1.4-1.1,2.6-2.5,3c-2.5,0.7-4.4,2.9-5,5.7c-0.1,0.5,0,0.9,0.3,1.3
	C0.6,29.8,1,30,1.5,30h15.4C15.1,28.2,14,25.7,14,23z"
          />
        </svg>
        <FeaturesText>
          Технічне обслуговування та системна online підтримка. Віддалене
          підключення до програмного забезпечення заводу, діагностування та
          детальна консультація.
        </FeaturesText>
      </FeaturesItem>
      <FeaturesItem>
        <svg
          id="_x32_"
          width="80"
          height="80"
          version="1.1"
          viewBox="0 0 512 512"
        >
          <path
            fill="#02759e"
            d="M255.366 141.046c-7.4 3.583-14.732 8.548-21.533 15.357-34.091 34.098-65.081 65.088-65.081 65.088l.013.02c-.185.186-.371.338-.557.53-8.824 8.831-9.174 22.909-1.025 32.146.323.371.668.736 1.025 1.086 9.161 9.174 24.036 9.196 33.232 0l35.797-35.797c6.176 2.263 12.248 3.583 18.074 4.243 7.937.88 15.392.55 22.022-.385 16.162-2.29 14.47-1.623 23.844-4.704 9.353-3.068 19.862-9.354 19.862-9.354l6.362 6.355c.701.681 16.919 16.925 25.192 25.185 1.465 1.471 2.709 2.682 3.542 3.549.956.997 2.022 1.719 2.682 2.682l41.278 41.279c11.898-13.35 25.488-33.232 23.81-56.058L320.763 129.14s-35.701-2.551-65.397 11.906zM261.115 394.362c-9.134-9.147-23.961-9.147-33.101 0l-6.794 6.794c9.119-9.132 9.112-23.926-.021-33.066-9.14-9.126-23.947-9.126-33.087.007 9.14-9.133 9.14-23.94 0-33.087-9.133-9.148-23.947-9.133-33.087 0 9.14-9.133 9.14-23.947 0-33.095-9.134-9.132-23.947-9.132-33.088.014l-20.46 20.453c-9.14 9.147-9.14 23.947 0 33.094 9.133 9.134 23.941 9.134 33.08 0-9.14 9.134-9.14 23.947 0 33.087 9.147 9.133 23.954 9.133 33.094 0-9.14 9.133-9.14 23.941 0 33.088 9.14 9.133 23.947 9.133 33.088 0l6.802-6.809c-9.119 9.147-9.113 23.94.02 33.081 9.14 9.132 23.947 9.132 33.088 0l20.467-20.468c9.132-9.153 9.132-23.96-.001-33.093zM507.987 178.28 387.543 57.822c-5.351-5.337-14.002-5.337-19.339 0l-38.631 38.63c-5.337 5.337-5.337 13.989 0 19.333l120.458 120.451c5.33 5.35 13.996 5.35 19.326 0l38.63-38.638c5.351-5.322 5.351-13.974 0-19.318zm-34.332 26.712c-5.75 5.736-15.048 5.736-20.777 0-5.735-5.743-5.735-15.041 0-20.777 5.729-5.736 15.027-5.736 20.777 0 5.736 5.736 5.729 15.034 0 20.777zM182.417 99.864l-38.624-38.63c-5.336-5.337-13.995-5.337-19.332 0L4.003 181.691c-5.337 5.323-5.337 13.989 0 19.319l38.631 38.644c5.33 5.331 14.002 5.331 19.325 0l120.458-120.458c5.344-5.337 5.344-13.989 0-19.332zM59.118 208.403c-5.736 5.729-15.04 5.729-20.777 0-5.735-5.742-5.735-15.041 0-20.777 5.736-5.735 15.041-5.735 20.777 0 5.736 5.736 5.736 15.034 0 20.777z"
            className="st0"
          />
          <path
            fill="#02759e"
            d="m397.528 312.809-7.468-7.482-72.509-72.509-4.883 2.166-5.316 1.919-.384.117c-.936.296-9.684 2.971-26.932 5.412-9.12 1.273-18.156 1.431-26.904.434-3.459-.385-6.898-.95-10.296-1.692l-27.757 27.744c-16.678 16.678-43.836 16.678-60.514 0-.585-.591-1.149-1.19-1.671-1.781l-.179-.2c-10.529-11.939-13.204-28.28-8.252-42.461l10.673-16.609-.02-.02 65.081-65.074c2.647-2.641 5.426-5.103 8.314-7.428-20.281-3.982-37.296-2.806-37.296-2.806L88.093 235.679c-1.389 18.988 11.651 39.799 20.928 51.952 16.692-15.963 43.239-15.756 59.641.654 6.107 6.1 9.952 13.617 11.574 21.498 7.895 1.637 15.406 5.475 21.513 11.582 6.107 6.114 9.952 13.631 11.575 21.519 7.888 1.623 15.412 5.46 21.513 11.568 4.078 4.078 7.152 8.783 9.222 13.817 11.1-.137 22.242 4.016 30.688 12.455 16.65 16.636 16.643 43.733 0 60.363l-6.809 6.822 3.411 3.412c9.148 9.147 23.954 9.147 33.095 0 9.14-9.134 9.14-23.947 0-33.088l6.808 6.83c9.147 9.133 23.947 9.133 33.087 0 9.14-9.147 9.147-23.954 0-33.101 9.147 9.147 23.947 9.147 33.087 0 9.134-9.126 9.154-23.94 0-33.088 9.154 9.148 23.954 9.148 33.088 0 9.147-9.132 9.147-23.947 0-33.08l-12.986-12.985z"
            className="st0"
          />
        </svg>
        <FeaturesText>30 років досвіду в галузі вібропресування</FeaturesText>
      </FeaturesItem>
      <FeaturesItem>
        <svg
          fill="#02759e"
          width="70"
          height="70"
          viewBox="0 0 50 50"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M39 2.03125C38.738281 2.0625 38.503906 2.199219 38.34375 2.40625L35.34375 6.21875C34.78125 5.488281 33.898438 5 32.90625 5L30.09375 5C28.394531 5 27 6.394531 27 8.09375L27 15.90625C27 17.605469 28.394531 19 30.09375 19L32.90625 19C33.898438 19 34.78125 18.511719 35.34375 17.78125L38.34375 21.59375C38.636719 21.960938 39.148438 22.066406 39.5625 21.84375L49.46875 16.4375C49.882813 16.214844 50.074219 15.730469 49.929688 15.285156C49.78125 14.835938 49.339844 14.5625 48.875 14.625C48.753906 14.644531 48.636719 14.6875 48.53125 14.75L39.375 19.78125L36 15.5L36 8.53125L39.375 4.25L48.53125 9.25C48.835938 9.457031 49.230469 9.472656 49.550781 9.292969C49.871094 9.113281 50.0625 8.769531 50.042969 8.402344C50.027344 8.035156 49.804688 7.710938 49.46875 7.5625L39.5625 2.15625C39.390625 2.058594 39.195313 2.015625 39 2.03125 Z M 9 3C4.039063 3 0 7.039063 0 12C0 16.960938 4.039063 21 9 21C13.960938 21 18 16.960938 18 12C18 7.039063 13.960938 3 9 3 Z M 18.6875 7C19.480469 8.519531 20 10.171875 20 12C20 13.828125 19.480469 16.480469 18.6875 18L24.96875 18C24.894531 17.652344 24.90625 16.367188 24.90625 16L24.90625 8C24.90625 7.632813 24.894531 7.347656 24.96875 7 Z M 9 7.34375C11.558594 7.34375 13.65625 9.441406 13.65625 12C13.65625 14.558594 11.558594 16.65625 9 16.65625C6.441406 16.65625 4.34375 14.558594 4.34375 12C4.34375 9.441406 6.441406 7.34375 9 7.34375 Z M 17.8125 18.5625C15.804688 21.242188 12.601563 23 9 23C7.773438 23 6.601563 22.777344 5.5 22.40625L14.28125 38.96875L29.34375 38.96875 Z M 6.875 41C5.253906 41 4 42.316406 4 44L4 49C4 49.582031 4.417969 50 5 50L39 50C39.582031 50 40 49.582031 40 49L40 44C40 42.316406 38.746094 41 37.125 41Z" />
        </svg>
        <FeaturesText>
          Проектування систем автоматизації та гідравліки. Підготовка
          механічних, гідравлічних, електричних, електронних систем та
          програмного забезпечення
        </FeaturesText>
      </FeaturesItem>
      <FeaturesItem>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="70"
          height="70"
          viewBox="0 0 16 16"
        >
          <g data-name="Layer 2">
            <path
              fill="#02759e"
              d="M14 11.05V8.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V11H6V8.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v2.55A2.5 2.5 0 0 0 2.5 16h11a2.5 2.5 0 0 0 .5-4.95ZM6 12a2.46 2.46 0 0 0 0 3H4.49a2.46 2.46 0 0 0 0-3H6Zm.49 1.5A1.5 1.5 0 1 1 8 15a1.5 1.5 0 0 1-1.5-1.5ZM10 12h1.52a2.46 2.46 0 0 0 0 3H10a2.46 2.46 0 0 0 0-3Zm1-3h2v2h-2ZM3 9h2v2H3Zm-2 4.5A1.5 1.5 0 1 1 2.5 15 1.5 1.5 0 0 1 1 13.5ZM13.5 15a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5ZM2 7a.5.5 0 0 0 0-1 1 1 0 1 1 1-1 .5.5 0 0 0 1 0 2 2 0 0 0-2-2V2h4a2 2 0 0 0 4 0h4v1a2 2 0 0 0-2 2 .5.5 0 0 0 1 0 1 1 0 1 1 1 1 .5.5 0 0 0 0 1 2 2 0 0 0 1-3.72V1.5a.5.5 0 0 0-.5-.5H9.72a2 2 0 0 0-3.44 0H1.5a.5.5 0 0 0-.5.5v1.78A2 2 0 0 0 2 7Zm6-6a1 1 0 1 1-1 1 1 1 0 0 1 1-1Z"
              data-name="Layer 1"
            />
          </g>
        </svg>
        <FeaturesText>
          Монтаж, пуско-наладка обладнання та інструктаж персоналу.
        </FeaturesText>
      </FeaturesItem>
    </FeaturesContainer>
  );
}
