import {
  ProductionContainer,
  ProductionText,
  ProductionTitel,
  PtoductionImg,
} from './Beton6.styled';

export default function Beton6() {
  return (
    <ProductionContainer>
      <div>
        <ProductionTitel>Сито вібраційне ВС</ProductionTitel>
      </div>
      <div>
        <ProductionText>
          Особливо важливим моментом можна назвати застосування вібросита під
          час виготовлення тротуарної плитки методом вібропресування. У цьому
          випадку, однорідність отриманого на віброситі вихідного матеріалу,
          наприклад піску, має важливе значення, оскільки в процесі
          перемішування сухих і напівсухих сумішей можуть утворюватися дрібні
          камінці (окатиші). Зразками якісної фактури, отриманої завдяки
          застосуванню вібросита, можна назвати такі будівельні вироби як
          тротуарна плитка, створена вібропресованим методом, бордюрний та
          облицювальний камінь, а також фігурні архітектурні елементи.
        </ProductionText>
        <ProductionText>
          Вібросито складається з потужного каркаса, в якому закріплено
          прямокутну металеву скриньку на жорстких пружинах. Дно металевого
          ящика виконане зі змінної сітки з отворами певного калібру. Під
          металевим сітчастим ящиком передбачений спеціальний лоток для прийому
          готової просіяної фракції. Якісне просіювання сипучих матеріалів та
          постійне струшування у віброситі відбувається завдяки розташованому
          під лотком активному кривошипному механізму.
        </ProductionText>
        <ProductionText>
          <b>Вібросито, має такі переваги як:</b>
        </ProductionText>

        <ProductionText>
          - високий рівень ефективності просіювання;
        </ProductionText>
        <ProductionText>
          - універсальність при виборі режимів для різних матеріалів;
        </ProductionText>
        <ProductionText>
          - легка та зручна заміна сітчастого каркасу;
        </ProductionText>
        <ProductionText>- висока енергоефективність;</ProductionText>
        <ProductionText>
          <b>Технічна характеристика обладнання:</b>
        </ProductionText>
        <ProductionText>
          Продуктивність від 1 до 40 куб.м/год (в залежності від калібру сітки
          та активної площі)
        </ProductionText>
        <PtoductionImg>
          <img
            src={process.env.PUBLIC_URL + '/imgs/production/press-form/41.jpg'}
            alt="Бетонозмішувач "
          />{' '}
          <img
            src={process.env.PUBLIC_URL + '/imgs/production/press-form/51.jpg'}
            alt="Бетонозмішувач "
          />
        </PtoductionImg>
      </div>
    </ProductionContainer>
  );
}
