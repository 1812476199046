import {
  ProductionContainer,
  ProductionText,
  ProductionTitel,
  PtoductionImg,
  FlexBlock,
} from './Production5.styled';

export default function Production5() {
  return (
    <ProductionContainer>
      <div>
        <ProductionTitel>
          Автоматичні лінії брикетування для металургії
        </ProductionTitel>
      </div>
      <div>
        <ProductionText>
          Нині у металургійній галузі виникла значна необхідність до
          «брикетування» дрібнодиспенсорних та дрібнофракційних залізо- та
          вуглецевмісних матеріалів.
        </ProductionText>
        <ProductionText>
          Важливість завдання пов'язана з тим, що при видобутку вугілля та
          залізняку утворюється значна кількість сировини дрібних фракцій. Крім
          того, на кожному металургійному підприємстві є значні техногенні
          родовища залізовмісних відходів, які постійно поповнюються. Дані
          матеріали необхідно переробляти, що неможливо зробити без попереднього
          окускования, тобто брикетування.
        </ProductionText>
        <ProductionText>
          Сховища відходів металургійної промисловості, які займають величезні
          земельні території, розташовані зазвичай у межах підприємства і
          можуть мати негативний вплив на здоров'я населення. Брикетування є
          єдиною екологічно прийнятною технологією, оскільки не потребує високих
          температур та гарантує практичну безвідходність.
        </ProductionText>
        <ProductionText>
          Машинобудівна компанія ТДВ «Моторика-Юг», виробник промислового
          обладнання випускає спеціалізовані автоматизовані брикетувальні
          (бітінгові) агрегати для дрібнофракційних рудних матеріалів і відходів
          промисловості продуктивністю до 80…100 тисяч тонн/рік, що працюють за
          технологією вібропресування. Необхідність брикетування дрібнозернистих
          рудних матеріалів, вугілля і пилу, а також повернення до виробництва
          дрібних відходів металургійного виробництва в даний час дуже
          актуальна. Для металургійних підприємств переробка відходів
          виробництва за технологією брикетування - це значне зниження
          собівартості продукції і, як наслідок, підвищення
          конкурентоспроможності.
        </ProductionText>
        <PtoductionImg>
          <figure>
            <img
              src={
                process.env.PUBLIC_URL +
                '/imgs/production/vibropress/product51.jpg'
              }
              alt="Вібропрес PGV-120U "
            />
            <figure></figure>
          </figure>
        </PtoductionImg>
        <ProductionTitel>
          Ми пропонуємо обладнання для брикетування під ключ:
        </ProductionTitel>
        <ProductionText>
          - дослідження сировини вашого виробництва;
        </ProductionText>
        <ProductionText>
          - розробка технологічної схеми розміщення обладнання у виробничому
          корпусі і на промислових майданчиках;
        </ProductionText>
        <ProductionText>
          - розробка проектної документації та експертиза проекту;
        </ProductionText>
        <ProductionText>
          - технологічне забезпечення виробництва брикету до виведення його на
          проектні потужності;
        </ProductionText>
        <ProductionText>
          - визначення комплектації автоматичної технологічної лінії з основним
          і допоміжним обладнанням;
        </ProductionText>
        <ProductionText>
          - визначення техніко-економічних показників виробництва для розробки
          бізнес-плану брикетного виробництва;
        </ProductionText>
        <ProductionText>
          - розробка технічної та технологічної документації;
        </ProductionText>
        <ProductionText>
          - виготовлення та постачання основного та допоміжного технологічного
          обладнання;
        </ProductionText>
        <ProductionText>
          - прив'язка технологічного обладнання у виробничому корпусі або на
          новій виробничій ділянці;
        </ProductionText>
        <ProductionText>
          - навчання персоналу і розробка технологічних інструкцій з
          виготовлення брикетів.
        </ProductionText>
        <ProductionTitel>Переваги технології вібропресування</ProductionTitel>
        <ProductionText>
          Брикетування за технологією вібропресування – формування з одночасним
          впливом на формуючу суміш вібрації і тиску. Брикети формуються на
          вібропрессових установках (брикетувальна машина - вібропресс). Під
          впливом вібрації частинки матеріалів в складі знаходять своє
          оптимальне положення без виникнення будь-яких напружень і пружних
          деформацій.
        </ProductionText>
        <PtoductionImg>
          <figure>
            <img
              src={
                process.env.PUBLIC_URL +
                '/imgs/production/vibropress/product52.jpg'
              }
              alt="Вібропрес PGV-120U "
            />
            <figure></figure>
          </figure>
        </PtoductionImg>
        <ProductionText>
          Готові брикети з металу, вугілля, відходів виробництва можуть
          виготовлятися у вигляді циліндра, чотири-, шести-, восьмикутної призми
          з будь-яким заданим співвідношенням довжини, ширини, висоти.
        </ProductionText>
        <PtoductionImg>
          <figure>
            <img
              src={
                process.env.PUBLIC_URL +
                '/imgs/production/vibropress/product53.jpg'
              }
              alt="Вібропрес PGV-120U "
            />
            <figure></figure>
          </figure>
        </PtoductionImg>
      </div>
      <ProductionText>
        Питомий тиск на суміш при формуванні дуже незначний і не перевищує 2
        МПа. З точки зору енергоспоживання цей спосіб є найбільш економічним.
        Споживання електроенергії не перевищує 10 кВт/т. Витрата стисненого
        повітря і теплової енергії (не більше 0, 04 Гкал/т) незначний.
        Гранулометричний склад сировини варіюється від 0 до 20 мм. Початкова
        вологість сировини становить від 0 до 15%.
      </ProductionText>
      <ProductionText>
        Приготування шихтової суміші здійснюється в змішувачах планетарного
        типу, оснащених швидкодіючими активаторами, що забезпечують ідеальну
        гомогенізацію суміші заряду.
      </ProductionText>
      <FlexBlock>
        <img
          src={
            process.env.PUBLIC_URL + '/imgs/production/vibropress/product54.jpg'
          }
          alt="Вібропрес PGV-120U "
          width='350'
        />
        <div
          style={{ textAlign: 'start', paddingLeft: '50px', fontSize: '18px' }}
        >
          <p>
            Спосіб виготовлення брикетів методом вібропресування має суттєві
            переваги перед іншими способами формування:
          </p>
          <p>- висока продуктивність;</p>
          <p>- можливість автоматизації всіх процесів;</p>
          <p>
            - використання великої кількості компонентів та їх точне дозування,
            що дозволяє одержати заданий хімічний склад;
          </p>
          <p>- високі фізико-механічні властивості одержуваного брикету;</p>
          <p>
            - можливість отримувати брикет заданих геометричних розмірів, зміну
            цих розмірів у разі потреби;
          </p>
          <p>- низька енергоємність.</p>
          <ProductionTitel>ПЕРЕВАГИ БРИКЕТУ</ProductionTitel>
          <ProductionText>
            До переваг брикету (штучного композиту) перед традиційним кусковим
            матеріалом, можна віднести наступні фактори:
          </ProductionText>
          <ProductionText>
            - можливість використання багатих дрібнофракційних матеріалів;
          </ProductionText>

          <ProductionText>
            - можливість виготовлення виробу будь-якої конфігурації: чотири-;
            шести-, восьмикутна призма, циліндр і т.д.;
          </ProductionText>
          <ProductionText>
            - геометричні розміри брикетів (висота) від 30 до 300 мм в
            залежності від вимог металургійної плавки і конструкції
            навантажувальних агрегатів;
          </ProductionText>
          <ProductionText>
            - частинки різних компонентів заряду - оксидів і відновника -
            знаходяться в безпосередньому контакті, що значно прискорює
            швидкість реакцій взаємодії;
          </ProductionText>
          <ProductionText>
            - до складу брикетів можуть вводитися найрізноманітніші компоненти
            (в тому числі флюси, легування, вугільний пил, накип, коксові
            дрібнодисперси, тріски та ін.);
          </ProductionText>
          <ProductionText>
            - можливість використання «бідних» матеріалів, при цьому кінцевий
            хімічний склад коригується введенням в брикет необхідних
            компонентів;
          </ProductionText>
          <ProductionText>
            - можливість регулювання «гарячої сили» шляхом зміни типу і
            кількості введених в'яжучих речовин;
          </ProductionText>
          <ProductionText>
            - висока механічна міцність брикетів і можливість її регулювання;
          </ProductionText>
          <ProductionText>
            - стійкість брикетів до погодних умов і можливість транспортування у
            відкритих піввагонах, не боячись замерзнути і «злипнутися».
          </ProductionText>
        </div>
      </FlexBlock>
      <PtoductionImg>
        <figure>
          <img
            src={
              process.env.PUBLIC_URL +
              '/imgs/production/vibropress/product55.jpg'
            }
            alt="Вібропрес PGV-120U "
          />
          <figure></figure>
        </figure>
      </PtoductionImg>
      <ProductionTitel>ВИДИ МЕТАЛУРГІЙНИХ БРИКЕТІВ</ProductionTitel>
      <ProductionText>
        - самовідновлювальні залізовуглецеві брикети;
      </ProductionText>
      <ProductionText>
        - брикети для промивання роги доменної печі;
      </ProductionText>
      <ProductionText>- брикети-карбюратори (вуглецевмісні);</ProductionText>
      <ProductionText>
        - шихтові брикети для виробництва феромарганцю, ферохрому, феросиліцію;
      </ProductionText>
      <ProductionText>
        - брикети на основі феросплавного відсіву;
      </ProductionText>
      <ProductionText>
        - «приготування» брикетів для ремонту футерування;
      </ProductionText>
      <ProductionText>
        - брикети на основі піритних шлаків, червоних мулів і т.д.;
      </ProductionText>
      <ProductionText>
        - флюсові брикети на основі кальцієво-магнієвмісних відходів.
      </ProductionText>
    </ProductionContainer>
  );
}
