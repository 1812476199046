import {
  ProductionContainer,
  ProductionText,
  ProductionTitel,
  PtoductionImg,
} from './Transport2.styled';

export default function Transport2() {
  return (
    <ProductionContainer>
      <div>
        <ProductionTitel>Система з піддонами на ніжках</ProductionTitel>
      </div>
      <div>
        <ProductionText>
          Дана система призначена для роботи з дерев'яними, сталевими чи
          композитними піддонами на ніжках. Висота ніжок може бути від 100 до
          320 мм в залежності від висоти виробів, що випускаються. Система з
          піддонами на ніжках дозволяє відмовитися необхідності установки
          дорогих камер тепловологої обробки виробів, так як піддони
          штабелюються один на одного і легко транспортуються в будь-яке
          відведене під витримку виробів місце. Для безперервної роботи лінії у
          8-годинну зміну необхідно мати щонайменше 1200 технологічних піддонів.
        </ProductionText>
        <ProductionText>
          Комплектація лінії індивідуальна для кожного проекту та залежить від
          вимог замовника та умов експлуатації. Залежно від особливостей місця
          установки вузли лінії можуть бути спроектовані в пряму лінію або
          «U»-подібну. Все обладнання може бути оснащене огорожами безпеки, дверима
          та датчиками.
        </ProductionText>

        <PtoductionImg>
          <img
            src={process.env.PUBLIC_URL + '/imgs/production/transport/44.jpg'}
            alt="Бетонозмішувач "
          />
        </PtoductionImg>
        <ProductionText>
          Для тепловологої обробки продуктів було розроблено систему металевих
          модулів. Це забезпечує швидке та безпечне складання елементів, більш
          високу і в будь-якому випадку регульовану точність рейок для ковзання
          автоматичних вантажно-розвантажувальних візків, високу безпеку при
          експлуатації.
        </ProductionText>
        <PtoductionImg>
          <img
            src={process.env.PUBLIC_URL + '/imgs/production/transport/55.jpg'}
            alt="Бетонозмішувач "
          />
        </PtoductionImg>
      </div>
    </ProductionContainer>
  );
}
