import React from 'react';
import { ServiceText, ServiceTitel, ServiceContainer } from './Service.styled';

export default function Service() {
  return (
    <ServiceContainer>
      <ServiceTitel>Сервіс</ServiceTitel>
      <ServiceText>
        Ми несемо відповідальність перед нашими клієнтами за обладнання, яке
        вони купують у нас та за послуги, які ми надаємо. Тому ми гарантуємо
        надійну післяпродажну підтримку для забезпечення максимальної простоти
        використання та обслуговування після встановлення виробничої лінії.
      </ServiceText>
      <ServiceText>
        Ми завжди готові допомогти нашим клієнтам протягом усього терміну
        експлуатації окремого обладнання або цілої виробничої лінії. Наше
        партнерство починається з продажу обладнання ТДВ «Моторика-Юг» і
        перетворюється на співпрацю на необмежений час.
      </ServiceText>
      <ServiceText>
        Наше обладнання може бути легко підключено до промислової мережі
        Ethernet на заводі клієнта, що дозволяє отримувати доступ до виробничого
        процесу з будь-якого комп'ютера.
      </ServiceText>
      <ServiceText>
        Ми пропонуємо стандартний пакет "Віддалений доступ через VPN", який
        передбачає оснащення обладнання адаптером дистанційного керування та
        обслуговування. За наявності підключення до інтернету цей адаптер
        дозволяє відстежувати процес виробництва з будь-якої точки світу. У разі
        несправності на заводі наші спеціалісти з відділу технічної підтримки
        можуть підключитися до виробничої лінії та виявити причину помилки,
        усунути неполадки програмного забезпечення або оновити систему. У
        більшості випадків вирішення проблем виявляється за допомогою цієї
        послуги, яка надається безкоштовно протягом гарантійного періоду та
        після закінчення терміну його дії.
      </ServiceText>
      <ServiceText>
        Якщо несправність не може бути усунена віддаленим підключенням, у
        найкоротші терміни наші фахівці приїжджають на ваше підприємство та
        знаходять причини збоїв.
      </ServiceText>
      <ServiceText>
        ТДВ «Моторика-Юг» пропонує своїм клієнтам безкоштовну технічну підтримку
        як під час гарантійного терміну обладнання, так і після закінчення
        терміну його дії.
      </ServiceText>
      <ServiceText>
        Технічна підтримка надається англійською, російською та українською
        мовами.
      </ServiceText>
      <ServiceTitel>Постачання запасних частин</ServiceTitel>
      <ServiceText>
        Ми відповідаємо за постачання всіх необхідних запчастин на термін служби
        технологічної лінії для виробництва бетонних виробів.
      </ServiceText>
      <ServiceText>
        Наш відділ логістики забезпечує найшвидше відвантаження необхідних
        запчастин із нашого складу запасних частин, що має широкий асортимент
        продукції.
      </ServiceText>
      <ServiceTitel>Поточний та капітальний ремонт</ServiceTitel>
      <ServiceText>
        Рекомендується щоденне та щорічне технічне обслуговування для
        довгострокової експлуатації обладнання та виробничої лінії. Крім того,
        якщо ви хочете провести модернізацію існуючого обладнання, щоб відновити
        початкову продуктивність і відповідати технологіям і вимогам, що
        постійно оновлюються, наші досвідчені інженери запропонують вам варіанти
        оновлення без високих інвестиційних витрат.
      </ServiceText>
      <ServiceText>
        Ми можемо провести капітальний ремонт та модернізацію будь-якого
        існуючого обладнання чи заводу незалежно від виробника (як для власного
        виробництва, так і для обладнання інших виробників). Устаткування марки
        ТДВ «Моторика-Юг» може бути інтегроване у ваш завод, забезпечивши
        синхронізовану роботу усієї виробничої лінії.
      </ServiceText>
      <ServiceText>
        Зв'яжіться з нами, якщо ви хочете продовжити термін служби вашого
        обладнання та оновити його за допомогою новітніх технологій.
      </ServiceText>
    </ServiceContainer>
  );
}
